import { Route, useNavigate } from "react-router-dom";

const editLocalstorage = () => {
  localStorage.removeItem("UserCred");
};

const Logout = () => {
  const navigate = useNavigate();
  editLocalstorage();
  navigate("/");
  window.location.href = "/";
  return <></>;
};

export default Logout;
