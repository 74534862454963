import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SignUpForm from "./signUpForm";
import { useState } from "react";
import { forwardRef, useImperativeHandle } from "react";

const SignUpModal = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleShow(user) {
      if (user) {
        setUser(user);
        setheading("Update ");
      } else {
        setUser("");
        setheading("Add ");
      }

      handleShow();
    },
  }));
  const [show, setShow] = useState(false);
  const [heading, setheading] = useState("Add");
  const [user, setUser] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{heading + "User"}</Modal.Title>
      </Modal.Header>
      <SignUpForm
        heading=""
        user={user}
        submitText={heading + "User"}
        hideModal={handleShow}
      />
      <Modal.Body></Modal.Body>
    </Modal>
  );
});

export default SignUpModal;
