import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import axios from "axios";
import { Route, useNavigate } from "react-router-dom";
import SignUpForm from "./signUpForm";

const Login = ({ setSideBarVisibility = (f) => f }) => {
  const background = require("../../images/background.jpg");
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Username) {
      alert("Username Can not be empty");
      return;
    } else if (!Password) {
      alert("Password Can not be empty");
      return;
    }
    const url = "/api/login";

    const userbody = {
      username: Username,
      password: Password,
    };

    axios.post(url, userbody).then((resp) => {
      if (resp.data) {
        localStorage.setItem("UserCred", JSON.stringify(userbody));
        setSideBarVisibility(false);
        navigate("/");
      } else {
        alert("Incorrect Credentials!!");
      }
    });
    // console.log(event);
  };

  setSideBarVisibility(true);

  return (
    <div style={{ backgroundImage: `url(${background})`, height: "100vh" }}>
      <div className="loginPan">
        <Form onSubmit={handleSubmit}>
          <h3>Sign In</h3>
          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              required
              id="username"
              placeholder="Enter username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="password"
              id="password"
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          {/* <Form.Group style={{ display: "flex", alignItems: "center" }}>
            <Form.Check
              id="remeber"
              type="checkbox"
              label="Remember Me"
              onChange={(e) => setRemember(!remember)}
            />
          </Form.Group> */}
          <Button className="btn btn-success" type="submit">
            Log In
          </Button>
          <Form.Group className="mt-3" style={{ textAlign: "right" }}>
            <Form.Label>
              Create <a href="/signUp">Account?</a>
            </Form.Label>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default Login;
