import React from "react";
import { Route, Navigate } from "react-router-dom";

const RouteGuard = ({ component: Component, ...rest }) => {
  function hasJWT() {
    let flag = false;

    localStorage.getItem("UserCred") ? (flag = true) : (flag = false);

    return flag;
  }

  return hasJWT() ? (
    <Component {...rest} />
  ) : (
    <Navigate to={{ pathname: "/login" }} />
  );
};

export default RouteGuard;
