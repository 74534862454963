import SignUpForm from "./signUpForm";

const SignUp = ({ setSideBarVisibility = (f) => f }) => {
  const background = require("../../images/background.jpg");
  setSideBarVisibility(true);
  return (
    <div style={{ backgroundImage: `url(${background})`, height: "100vh" }}>
      <div className="loginPan ">
        <SignUpForm heading="Sign Up"></SignUpForm>
      </div>
    </div>
  );
};

export default SignUp;
