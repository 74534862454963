import douglas from "../images/douglas.png";

const Location = () => {
	

	return ( <div style={{padding:"20px"}}>
		<img src={douglas} alt="douglas" width={600}/>
	</div> );
}
 
export default Location;