import SignUpForm from "./login/signUpForm";

const Record = (props) => (
  <tr>
    <td>{props.user.firstname}</td>
    <td>{props.user.lastname}</td>
    <td>@{props.user.username}</td>
    <td>{props.user.email}</td>
    <td>{props.user.phoneNum}</td>
    <td>{props.user.department}</td>
    <td></td>
  </tr>
);
const Profile = ({ users = [] }) => {
  // console.log(users[0]);
  return (
    <div style={{ width: "35%" }}>
      <SignUpForm
        user={users.find(user=>user.username===JSON.parse(localStorage.getItem("UserCred")).username)}
        heading="Update User Profile"
        submitText="Update"
      ></SignUpForm>
    </div>
  );
};

export default Profile;
