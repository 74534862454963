import logo from "../images/logo.png";
import axios from "axios";
import { useEffect, useState } from "react";

const Header = () => {
  const [latitude, setLatitude] = useState("49.21");
  const [longitude, setLongitude] = useState("-122.9149726");
  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }

  function success(pos) {
    const crd = pos.coords;

    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    setLatitude(crd.latitude);
    setLongitude(crd.longitude);
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    getLocation();
    const key = "daa97db2e5184000041749ba223d50e1";
    const units = "metric";
    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${key}&units=${units}`;
    axios.get(url).then((resp) => {
      const icon = resp.data.weather[0].icon;
      const temp = `${resp.data.main.temp}°C`;
      const imageURL = `http://openweathermap.org/img/wn/${icon}@2x.png`;
      document.getElementById("weather").src = imageURL;
      document.getElementById("temp").textContent = temp;
    });
  }, []);

  return (
    <div
      style={{
        background: "rgb(10,25,41)",
        height: "100px",
        position: "absolute",
        width: "100%",
      }}
    >
      <img
        src={logo}
        style={{ height: "inherit", padding: "15px" }}
        alt="logo"
      />
      <figure  style={{ float: "right" , color: "white", padding:"5px"}}>
        <img id="weather" alt="weather" width={50}></img>
        <figcaption id="temp" />
      </figure>
    </div>
  );
};

export default Header;
