import LogBox from "./MiniComponent/log";
import ErrorLogBox from "./MiniComponent/errorLog";
const Home = () => {
  return (
    <div style={{ display: "flex" }}>
      <LogBox></LogBox>
      <ErrorLogBox></ErrorLogBox>
    </div>
  );
};

export default Home;
