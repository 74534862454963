import { useState, useEffect } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";

const LogUI = (props) => {
  return (
    <tr>
      <td>{props.oLog?.CreatedOn}</td>
      <td>{props.oLog?.Log}</td>
    </tr>
  );
};

const ErrorLogBox = () => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const url = "/api/getErrorLogs";
    axios.get(url).then((resp) => {
      setLogs(resp.data);
      console.log(resp.data);
    });
  }, []);

  return (
    <div
      style={{
        width: "40%",
        margin: "10px 10px 10px 10px",
        textAlign: "center",
      }}
    >
      <h2>Error Log Acitvity</h2>
      <div style={{ maxHeight: "40vh", overflow: "auto" }}>
        <Table striped style={{ border: "solid grey 1px" }}>
          <thead>
            <tr>
              <th>Create On</th>
              <th>Log</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => (
              <LogUI oLog={log} key={log._id} />
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ErrorLogBox;
