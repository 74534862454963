import Home from "./components/home";
import AddTask from "./components/addTask";
import Jobs from "./components/jobs";
import Schedule from "./components/schedule";
import Profile from "./components/profile";
import NotFound from "./components/notFound";
import SideBar from "./components/sideBar";
import Contact from "./components/contact";
import Location from "./components/location";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { useEffect, useState } from "react";
import Login from "./components/login/login";
import Logout from "./components/login/logout";
import Header from "./components/header";
import axios from "axios";
import RouteGuard from "./components/login/RouteGuard";
import SignUp from "./components/login/signUp";
import UserList from "./components/userList";

function App() {
  const [hide, setHide] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const url = "/api/userinfo";
    axios.get(url).then((resp) => {
      setUsers(resp.data);
    });
  }, []);

  var d = new Date();
  console.log(d.toLocaleDateString());

  return (
    <div className="App">
      <Header />
      <SideBar hide={hide} />
      <main>
        <Routes>
          <Route
            path="/"
            element={<RouteGuard component={Home} users={users} />}
          />
          <Route
            path="/userlist"
            element={<RouteGuard component={UserList} users={users} />}
          />
          <Route
            path="/login"
            element={<Login setSideBarVisibility={setHide} />}
          />
          <Route
            path="/schedule"
            element={<RouteGuard component={Schedule} users={users} />}
          />
          <Route
            path="/addtask"
            element={<RouteGuard component={AddTask} users={users} />}
          />
          <Route
            path="/jobs"
            element={<RouteGuard component={Jobs} users={users} />}
          />
          <Route
            path="/profile"
            element={<RouteGuard component={Profile} users={users} />}
          />
          <Route
            path="/about/us"
            element={<RouteGuard component={Contact} />}
          />
          <Route
            path="/about/location"
            element={<RouteGuard component={Location} />}
          />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/signup"
            element={<SignUp setSideBarVisibility={setHide} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
