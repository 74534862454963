import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import isCurrentUserAdmin from "../helper/isAdmin";

const SignUpForm = ({
  hideModal = (f) => f,
  user,
  heading = "Sign Up",
  submitText = "Sign Up",
}) => {
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [userName, setuserName] = useState("");
  const [email, setemail] = useState("");
  const [department, setdepartment] = useState("");
  const [phNumber, setphNumber] = useState("");
  const [passsword, setPassword] = useState("");
  const [role, setRole] = useState("user");
  const [isAdmin, setIsAdmin] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      if (submitText != "Sign Up") {
        isCurrentUserAdmin().then(({ data }) => {
          setIsAdmin(data);
          if (data && !user.role) {
            setRole("admin");
          }
        });
      }

      isMounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (user) {
      console.log(user);
      setfName(user.firstname);
      setlName(user.lastname);
      setuserName(user.username);
      setdepartment(user.department);
      setphNumber(user.phoneNum);
      setemail(user.email);
      setRole(user.role);
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const userbody = {
      username: userName,
      password: passsword,
      firstname: fName,
      lastname: lName,
      email: email,
      department: department,
      phoneNum: phNumber,
      role: role,
    };

    console.log(submitText);
    let url = "/api/createuser";

    if (submitText != "Sign Up" && submitText != "Add User") {
      url = `/api/updateuser/${user._id}`;

      axios({
        method: "PATCH",
        url,
        data: userbody,
      }).then((res) => {
        console.log(res);
        window.location.href = window.location.href;
      });
    } else {
      axios.post(url, userbody).then((resp) => {
        if (String(resp.data).includes("user submitted successfully")) {
          hideModal();
          if (submitText == "Sign Up") {
            localStorage.setItem("UserCred", JSON.stringify(userbody));
            window.location.href = "/";
          } else {
            window.location.href = window.location.href;
          }
        }
        alert(resp.data);
        console.log(resp);
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
      <h3>{heading}</h3>

      <Form.Group className="mb-3">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          required
          id="fName"
          defaultValue={user?.firstname}
          placeholder="Enter your First Name"
          onChange={(e) => setfName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          required
          id="lName"
          defaultValue={user?.lastname}
          placeholder="Enter your Last Name"
          onChange={(e) => setlName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>User Name</Form.Label>
        <Form.Control
          required
          id="userName"
          defaultValue={user?.username}
          placeholder="Enter your User Name"
          onChange={(e) => setuserName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          required
          type="email"
          id="email"
          defaultValue={user?.email}
          placeholder="Enter your email"
          onChange={(e) => setemail(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          required
          type="number"
          id="phNumber"
          defaultValue={user?.phoneNum}
          placeholder="Enter your phone Number"
          onChange={(e) => setphNumber(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Department</Form.Label>
        <Form.Control
          required
          id="department"
          defaultValue={user?.department}
          placeholder="Enter your department"
          onChange={(e) => setdepartment(e.target.value)}
        />
      </Form.Group>
      <Form.Group
        className="mb-3"
        style={{ display: isAdmin ? "block" : "none" }}
      >
        <Form.Label>Role</Form.Label>
        <Form.Select
          id="role"
          disabled={!isAdmin}
          onChange={(e) => {
            setRole(e.target.value);
            console.log(e.target.value);
          }}
          value={role}
        >
          <option key={1} value={"user"}>
            User
          </option>
          <option key={2} value={"admin"}>
            Admin
          </option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          required
          id="passsword"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Button
        className="btn btn-success"
        style={{
          textAlign: "center",
        }}
        type="submit"
      >
        {submitText}
      </Button>
    </Form>
  );
};

export default SignUpForm;
