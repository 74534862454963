import logo from "../images/logo.png";
import Profile from "./profile";
import Table from "react-bootstrap/Table";
import SignUpModal from "./login/signUpModal";
import Button from "react-bootstrap/Button";
import { useRef, useState } from "react";
import isCurrentUserAdmin from "./helper/isAdmin";
import axios from "axios";

const UserList = ({ users = [] }) => {
  const myRef = useRef();
  const [isAdmin, setIsAdmin] = useState(false);

  isCurrentUserAdmin().then(({ data }) => {
    setIsAdmin(data);
  });

  const Record = (props) => (
    <tr>
      <td>{props.user.firstname}</td>
      <td>{props.user.lastname}</td>
      <td>@{props.user.username}</td>
      <td>{props.user.email}</td>
      <td>{props.user.phoneNum}</td>
      <td>{props.user.department}</td>
      <td style={{ display: props.isAdmin ? "table-cell" : "none" }}>
        <Button onClick={props.handleChange}>Update</Button>
      </td>
      <td style={{ display: props.isAdmin ? "table-cell" : "none" }}>
        <Button variant="danger" onClick={props.handleDelete}>
          Delete
        </Button>
      </td>
      <td></td>
    </tr>
  );

  const handleChange = (user) => {
    console.log(user);
    myRef.current.handleShow(user);
  };

  const handleDelete = (_id) => {
    const url = `/api/userdelete/${_id}`;

    axios
      .delete(url)
      .then((resp) => {
        console.log(resp.data);
        window.location.href = window.location.href;
        alert(resp.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>All Users</h1>
      <SignUpModal ref={myRef}></SignUpModal>
      <div style={{ textAlign: "right", display: isAdmin ? "block" : "none" }}>
        <Button
          className="btn btn-success"
          onClick={() => {
            myRef.current.handleShow();
          }}
        >
          Add User
        </Button>
      </div>
      <Table striped>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Username</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Department</th>
            <th
              colSpan={2}
              style={{ display: isAdmin ? "table-cell" : "none" }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((users) => (
            <Record
              user={users}
              key={users._id}
              isAdmin={isAdmin}
              handleChange={() => handleChange(users)}
              handleDelete={() => handleDelete(users._id)}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UserList;
